import React from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-component'

import Press from './Press'
// import { smallBP, mediumBP } from '../../styles/breakpoint'

import styles from './Press.module.css'

export default function PressFeed({ className, items, ...props }) {
  // const [shouldBeResponsive, setShouldBeResponsive] = useState(false)
  if (items.length === 0) {
    return null
  }

  // const isMedium = useMediaQuery({
  //   query: `${medium} and ${smallMin}`,
  // })
  // const isSmall = useMediaQuery({ query: small })

  // let columnCount
  // if (isMedium && shouldBeResponsive) {
  //   columnCount = 2
  // } else if (isSmall && shouldBeResponsive) {
  //   columnCount = 1
  // } else {
  //   columnCount = 3
  // }

  // useEffect(() => {
  //   if (isMedium || isSmall) {
  //     setShouldBeResponsive(true) // workaround for SSR
  //   }
  // }, [isMedium, isSmall, setShouldBeResponsive])

  // const columns = useGroupColumns(items, columnCount)
  // const columnsCountBreakPoints = { 0: 1, [smallBP]: 2, [mediumBP]: 3 }

  return (
    <section className={[styles.feed, className].join(' ')} {...props}>
      <Masonry
        options={{
          columnWidth: `.${styles.item}`,
          itemSelector: `.${styles.item}`,
          percentPosition: true,
          // transitionDuration: 0,
        }}
      >
        {items.map(press => (
          <div
            key={press.id}
            className={[styles.columnPadding, styles.item].join(' ')}
          >
            <Press press={press} />
          </div>
        ))}
      </Masonry>

      {/* {columns.map(column => (
        <div
          key={column.map(item => item.id).join('-')}
          className={styles.column}
        >
          {column.map(press => (
            <div key={press.id} className={styles.columnPadding}>
              <Press press={press} />
            </div>
          ))}
        </div>
      ))} */}
    </section>
  )
}

PressFeed.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
}

PressFeed.defaultProps = {
  className: '',
  items: [],
}
