import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Image from '../Image'
import ResponsiveVideo from '../ResponsiveVideo'

import styles from './Slideshow.module.css'

export default function Slideshow() {
  const data = useStaticQuery(graphql`
    {
      allStrapiHomeSlideshow {
        nodes {
          images {
            vimeo_url
            width
            height
            id
            image_url
            image {
              extension
              childImageSharp {
                fluid(maxWidth: 2400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allStrapiHomeSlideshow: { nodes },
  } = data

  const [slideshow] = nodes

  const [currentSlide, setCurrentSlide] = useState(0)
  const timer = useRef()

  useEffect(() => {
    const nextSlide =
      currentSlide + 1 === slideshow.images.length ? 0 : currentSlide + 1
    timer.current = setTimeout(() => setCurrentSlide(nextSlide), 1000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [currentSlide])

  return (
    <div className={styles.container}>
      {slideshow.images.map((slide, index) => {
        if (slide.vimeo_url) {
          return (
            <div
              className={styles.slide}
              style={
                index === currentSlide
                  ? { visiblity: 'visible', position: 'relative' }
                  : { visibility: 'hidden', position: 'absolute' }
              }
            >
              <ResponsiveVideo
                key={slide.id}
                vimeoId={slide.vimeo_url}
                width={slide.width ? slide.width : undefined}
                height={slide.height ? slide.height : undefined}
                quality="1080p"
              />
            </div>
          )
        }

        if (!slide.image) {
          return null
        }

        return (
          <Image
            key={slide.id}
            style={
              index === currentSlide
                ? { visiblity: 'visible', position: 'relative' }
                : { visibility: 'hidden', position: 'absolute' }
            }
            className={styles.slide}
            image={slide.image}
            url={slide.image_url}
            loading="eager"
            fadeIn={false}
          />
        )
      })}
    </div>
  )
}

Slideshow.propTypes = {}
