import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import Image from '../Image'

import styles from './Press.module.css'
import ResponsiveVideo from '../ResponsiveVideo'

export default function Press({ press, ...props }) {
  const {
    date,
    image,
    image_url: imageUrl,
    url,
    fields: { bodyHTML, headlineHTML },
    vimeo,
  } = press

  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      <Card as="article" style={{ marginTop: 0 }} {...props}>
        <p className={styles.date}>{`Updates: ${date}`}</p>
        <h3
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: headlineHTML }}
        />
        <div
          className={['wysiwyg', styles.body].join(' ')}
          dangerouslySetInnerHTML={{ __html: bodyHTML }}
        />

        {vimeo ? (
          <ResponsiveVideo
            className={styles.image}
            vimeoId={vimeo.vimeo_url}
            width={vimeo.width ? vimeo.width : undefined}
            height={vimeo.height ? vimeo.height : undefined}
          />
        ) : (
          <Image className={styles.image} image={image} url={imageUrl} alt="" />
        )}
      </Card>
    </a>
  )
}

Press.propTypes = {
  press: PropTypes.shape({
    date: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      bodyHTML: PropTypes.string.isRequired,
      headlineHTML: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.object.isRequired,
    vimeo: PropTypes.object,
    image_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}
