import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Slideshow from '../components/Slideshow'
import { PressFeed } from '../components/Press'

const IndexPage = ({ location, data }) => {
  const {
    allStrapiHomeSlideshow: { nodes },
    allStrapiPress: { nodes: pressItems },
  } = data

  const [home] = nodes
  const {
    meta_description: metaDescription,
    meta_keywords: metaKeywords,
  } = home

  const meta = []

  if (metaKeywords) {
    meta.push({ name: 'keywords', content: metaKeywords })
  }

  return (
    <Layout location={location}>
      <SEO title="Home" description={metaDescription} meta={meta} />
      <Layout.Content>
        <Slideshow />
        <PressFeed items={pressItems} />
      </Layout.Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allStrapiHomeSlideshow {
      nodes {
        meta_description
        meta_keywords
      }
    }
    allStrapiPress(sort: { fields: date, order: DESC }) {
      nodes {
        url
        date(formatString: "D MMMM YYYY")
        id
        image_url
        vimeo {
          vimeo_url
          width
          height
        }
        fields {
          bodyHTML
          headlineHTML
        }
        image {
          extension
          base
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
